<template>
  <div>
    <b-form-group
      label="Doküman Notu"
      label-for="notes"
    >
      <validation-provider
        #default="{ errors }"
        name="Doküman Notu"
        rules="required"
      >
        <b-form-textarea
          v-model="dataItem.notes"
          placeholder="Doküman Notu"
        />
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
    <pre-loading
      v-if="uploading"
      class="my-2"
    />
    <template v-if="!dataItem.filename">
      <b-form-group
        label="Doküman"
        label-for="upload_file"
      >
        <validation-provider
          #default="{ errors }"
          name="Doküman"
          rules="required"
        >
          <b-form-file
            v-model="dataItem.upload_file"
            placeholder="Bir dosya seçin veya buraya bırakın..."
            drop-placeholder="Dosya buraya bırakın..."
            browse-text="Dosya Seçin"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </template>
    <template v-else>
      <b-button
        variant="success"
        :href="baseURL + dataItem.filename"
        target="_blank"
      >
        <FeatherIcon icon="DownloadIcon" />
        Dokümanı İndir
      </b-button>
    </template>
  </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import {
  BFormGroup, BFormTextarea,
  BFormFile, BButton,
} from 'bootstrap-vue'
import PreLoading from '@/layouts/components/common/PreLoading.vue'

export default {
  name: 'DocumentForm',
  components: {
    PreLoading,
    BFormFile,
    BFormTextarea,
    BFormGroup,
    ValidationProvider,
    BButton,
  },
  data() {
    return {
      baseURL: `${this.$store.state.app.baseURL}/media/damage_tracking_documents/`,
      required,
      locale: 'tr',
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['damageTrackingDocument/getData']
    },
    documentTypes() {
      return this.$store.getters['damageTrackingDocumentTypes/getDataList']
    },
    uploading() {
      return this.$store.getters['damageTrackingDocument/uploading']
    },
  },
  created() {
    this.getDocumentTypes()
  },
  methods: {
    getDocumentTypes() {
      return this.$store.dispatch('damageTrackingDocumentTypes/dataList')
    },
  },
}
</script>
