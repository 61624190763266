<template>
  <b-button
    variant="danger"
    :disabled="line.submitStatus"
    @click="actionMethods"
  >
    Kaydet
  </b-button>
</template>

<script>
import { BButton } from 'bootstrap-vue'

export default {
  name: 'SaveButtonLine',
  components: {
    BButton,
  },
  props: {
    actionMethods: {
      type: Function,
      required: true,
    },
  },
  computed: {
    line() {
      return this.$store.getters['damageTrackingLine/getData']
    },
  },
}
</script>

<style scoped>

</style>
